<template>
  <Header></Header>
  <div class="container">
    <el-tabs
      v-model="activeTab"
      class="login-Card"
      type="border-card"
      stretch="true"
    >
      <el-button style="display: none" :plain="true" @click="open"></el-button
      ><!-- 消息框 -->
      <el-tab-pane label="密码登录" name="passwordLogin">
        <el-form ref="pFormRef" :model="pForm" :rules="pRules">
          <el-form-item prop="loginId">
            <el-input v-model="pForm.loginId" placeholder="账号/手机号/邮箱" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="pForm.password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="密码"
            />
            <div class="eye-p">
              <img
                v-show="showPassword"
                src="@/assets/image/eye.png"
                @click="showPassword = false"
              />
              <img
                v-show="!showPassword"
                src="@/assets/image/closeEye.png"
                @click="showPassword = true"
              />
            </div>
          </el-form-item>
          <el-form-item prop="imageCode">
            <el-row :gutter="20" class="w100">
              <el-col :md="18" :lg="18" :sm="15" :xs="15">
                <el-input v-model="pForm.imageCode" placeholder="图形验证码" />
              </el-col>
              <el-col :span="6">
                <img
                  class="code-img"
                  alt="图形验证码"
                  title="点击刷新"
                  @click="refreshCode()"
                  :src="codeImg"
                />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-checkbox
              style="margin-right: 10px"
              v-model="customCheck1"
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
            />
            <label class="custom-control-label" for="customCheck1"
              >我已阅读并同意
              <router-link target="_blank" to="/agreement"
                >《外研教学评用户协议》</router-link
              >
              <router-link target="_blank" to="/privacy"
                >《外研教学评隐私政策》</router-link
              >
            </label>
          </el-form-item>
          <el-form-item>
            <el-button class="w100" type="primary" @click="login()"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="短信登录" name="smsLogin">
        <el-form ref="sFormRef" :model="sForm" :rules="sRules">
          <el-form-item prop="tel">
            <el-input v-model="sForm.tel" placeholder="手机号" />
          </el-form-item>
          <el-form-item prop="smsCode">
            <el-row :gutter="20" class="w100">
              <el-col :span="18" :xs="14">
                <el-input
                  v-model="sForm.smsCode"
                  placeholder="验证码"
                  @blur="verifyCode(sForm.tel, sForm.smsCode, 'login')"
                />
              </el-col>
              <el-col v-show="sendBtnShow" :span="6">
                <el-button
                  @click="
                    sendVerify(sForm.tel, 'login');
                    setTime();
                  "
                  >发送验证码</el-button
                >
              </el-col>
              <el-col v-show="!sendBtnShow" :span="6">
                <el-button>重新发送({{ counter }}s)</el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-checkbox
              style="margin-right: 10px"
              v-model="customCheck2"
              type="checkbox"
              class="custom-control-input"
              id="customCheck2"
            />
            <label class="custom-control-label" for="customCheck2"
              >我已阅读并同意
              <router-link target="_blank" to="/agreement"
                >《外研教学评用户协议》</router-link
              >
              <router-link target="_blank" to="/privacy"
                >《外研教学评隐私政策》</router-link
              >
            </label>
          </el-form-item>
          <el-form-item>
            <el-button class="w100" type="primary" @click="login()"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <div class="clearFloat">
        <div class="floatR ml20">
          <router-link to="/register">账号注册</router-link>
        </div>
        <div class="floatR">
          <router-link to="/forgetPassword">找回密码</router-link>
        </div>
      </div>
    </el-tabs>
    <el-dialog
      v-model="againAuthModal"
      title="二次身份验证"
      width="30%"
      align-center
    >
      <div>
        已向您的手机号<span>{{ enmobile }}</span> 发送短信
      </div>
      <br />
      <div>
        <el-input
          v-model="againAuthCode"
          placeholder="请输入短信验证码"
          required
        />
        <div v-show="againWaring" class="waring">{{ againWaringText }}</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="againAuthModal = false">取消</el-button>
          <el-button type="primary" @click="againAuth()"> 验证 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import { toLogin, toCheckToken, toAgainAuth } from "@/api/api.js";
import { onMounted, toRefs, reactive, getCurrentInstance } from "vue";
import {
  localStore,
  moduleInfos,
  verifyCode,
  sendVerify,
  _createUUID,
} from "@/utils/utils.js";
import {
  validatePassNull,
  validateNull,
  validateTel,
} from "@/utils/validate.js";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
//import { inject } from "vue-demi";
export default {
  components: { Header },
  setup() {
    const { proxy } = getCurrentInstance(); // 使用proxy代替ctx，因为ctx只在开发环境有效
    const router = useRouter();
    //const reload = inject("reload");
    const state = reactive({
      codeImg: "",
      activeTab: "passwordLogin",
      loginType: "",
      pForm: {
        loginId: "",
        password: "",
        imageCode: "",
      },
      sForm: {
        tel: "",
        smsCode: "",
      },
      uuId: "",
      customCheck1: false,
      customCheck2: false,
      channel: router.currentRoute.value.query.channel,
      yjChannelId: window.sessionStorage.getItem("yjChannelId"),
      pFormRef: null,
      sFormRef: null,
      sendBtnShow: true,
      counter: 60,
      hrefCode: router.currentRoute.value.query.appCode,
      hrefUrl: router.currentRoute.value.query.appUrl, //上线前需修改
      againAuthModal: false,
      enmobile: "",
      againAuthCode: "",
      authMobile: "",
      againWaring: false,
      againWaringText: "",
      keyRedirect: "fltrp-home-redirect-after-login",
      showPassword: false,
    });
    onMounted(() => {
      if (state.channel) {
        window.sessionStorage.setItem("yjChannelId", state.channel);
        state.yjChannelId = window.sessionStorage.getItem("yjChannelId");
      }
      if (state.hrefCode) {
        window.sessionStorage.setItem("appCode", state.hrefCode);
      }
      if (state.hrefUrl) {
        window.sessionStorage.setItem("appUrl", state.hrefUrl);
      }
      state.hrefCode = window.sessionStorage.getItem("appCode");
      state.hrefUrl = window.sessionStorage.getItem("appUrl");
      refreshCode();
      checkToken();
    });
    /* const initRedirect = () => {
      window.sessionStorage.setItem(state.keyRedirect,window.location.href);
    }; */
    const getRedirect = () => {
      return window.sessionStorage.getItem(state.keyRedirect);
    };
    const clearRedirect = () => {
      window.sessionStorage.removeItem(state.keyRedirect);
    };
    const checkToken = () => {
      toCheckToken()
        .then((response) => {
          if (response["status"] == 0) {
            if (response["msg"] === "鉴权成功") {
              proxy.$haveToken = true;
              var haveAuthArr = [];
              // 有路径跳转地址时
              var redirectUrl = getRedirect();
              if (redirectUrl) {
                clearRedirect();
                window.location.href = redirectUrl;
              } else if (state.hrefCode && state.hrefUrl) {
                info.forEach(function (item) {
                  haveAuthArr.push(item.appCode);
                });
                if (haveAuthArr.indexOf(state.hrefCode) !== -1) {
                  //有权限直接跳转子系统
                  window.location.href = state.hrefUrl;
                } else {
                  // 无权限，跳转至提示无权限页面
                  router.push({
                    path: "/noAuth",
                    query: {
                      appCode: state.hrefCode,
                    },
                  });
                }
              } else {
                // 带有悦教大讲堂重定向的地址
                if (state.yjChannelId) {
                  if (state.yjChannelId == "fjyd") {
                    window.location.href = "yuejiao/route.html?channel=fjyd";
                    return;
                  }
                } else {
                  //无跳转路径时跳转"我的"页面
                  router.push("/myApp");
                }
              }
            }
          } else {
            return;
          }
        })
        .catch(() => {
          //haveToken = false;
          return false;
        });
    };
    //获取图片验证码
    const refreshCode = () => {
      // 生成32位UUID
      state.uuId = _createUUID();
      //设置图片验证码
      state.codeImg =
        process.env.VUE_APP_BASE_PATH + "/auth/code?clientId=" + state.uuId;
    };
    //登录
    const login = () => {
      if (state.activeTab == "passwordLogin") {
        localStore("loginId", state.pForm.loginId);
        localStore("loginType", 1);
      } else {
        localStore("loginId", state.sForm.tel);
        localStore("loginType", 2);
      }
      checkAuth();
    };
    const checkAuth = () => {
      var datas;
      var checked;
      var valided = false;
      //判断是哪个登录方式，然后处理数据
      if (localStore("loginType") == 1) {
        //密码登录
        state.pFormRef.validate((valide) => {
          console.log(valide);
          if (valide) {
            var userName = proxy.$encryption(state.pForm.loginId);
            var password = proxy.$encryption(state.pForm.password);
            checked = state.customCheck1;
            datas = {
              loginType: 1,
              userName: userName,
              password: password,
              clientId: state.uuId,
              imageCode: state.pForm.imageCode,
            };
            valided = true;

            if (valided) {
              if (checked) {
                //调用登录接口进行登录
                toLogin(datas).then((response) => {
                  if (response["status"] == 0) {
                    var rolesArr =
                      response["data"]["additionalInformation"]["userAppRoles"];
                    for (var i = 0; i < rolesArr.length; i++) {
                      var moduleInfo = moduleInfos[rolesArr[i].appCode];
                      if (moduleInfo) {
                        rolesArr[i].url = moduleInfo["url"];
                        rolesArr[i].img = "image/" + moduleInfo["logo"];
                      }
                    }
                    localStore("token", response["data"]["accessToken"]);
                    localStore("tokenType", response["data"]["tokenType"]);
                    localStore(
                      "refreshToken",
                      response["data"]["refreshToken"]
                    );
                    localStore(
                      "isExpiredPass",
                      response["data"]["additionalInformation"]["isExpiredPass"]
                    );
                    dealLogin(response["data"], state.yjChannelId);
                  } else if (response["status"] == 1006) {
                    ElMessage({
                      message: "请修改密码,即将跳转修改密码页.......",
                      type: "warning",
                    });
                    setTimeout(
                      () =>
                        router.push({
                          path: "/modifyPassword",
                          query: {
                            userName: state.pForm.loginId.trim(),
                          },
                        }),
                      3000
                    );
                  } else if (response["status"] == 1100) {
                    state.authMobile =
                      response["data"]["additionalInformation"]["mobile"];
                    var reg = /(\d{3})\d{4}(\d{4})/;
                    state.enmobile = state.authMobile.replace(reg, "$1****$2");
                    state.againAuthModal = true;
                    refreshCode();
                  } else if (response["status"] == 1001) {
                    ElMessage({
                      message:
                        response["msg"] +
                        "<br/>*注意：请正确输入并注意字母需区分大小写，特殊字符需区分中英文。",
                      dangerouslyUseHTMLString: true,
                      type: "warning",
                      center: true,
                    });
                    refreshCode();
                  } else {
                    ElMessage({
                      message: response["msg"],
                      type: "warning",
                    });
                    refreshCode();
                  }
                  //refreshCode();
                });
              } else {
                ElMessage({
                  message: "请阅读并选中协议～",
                  type: "warning",
                });
              }
            } else {
              return;
            }
          } else {
            valided = false;
            return;
          }
        });
      } else {
        //手机号登录
        state.sFormRef.validate((valide) => {
          if (valide) {
            checked = state.customCheck2;
            datas = {
              loginType: 2,
              mobile: state.sForm.tel,
              code: state.sForm.smsCode,
            };
            valided = true;
            if (valided) {
              if (checked) {
                //调用登录接口进行登录
                toLogin(datas).then((response) => {
                  if (response["status"] == 0) {
                    var rolesArr =
                      response["data"]["additionalInformation"]["userAppRoles"];
                    for (var i = 0; i < rolesArr.length; i++) {
                      var moduleInfo = moduleInfos[rolesArr[i].appCode];
                      if (moduleInfo) {
                        rolesArr[i].url = moduleInfo["url"];
                        rolesArr[i].img = "image/" + moduleInfo["logo"];
                      }
                    }
                    localStore("token", response["data"]["accessToken"]);
                    localStore("tokenType", response["data"]["tokenType"]);
                    localStore(
                      "refreshToken",
                      response["data"]["refreshToken"]
                    );
                    localStore(
                      "isExpiredPass",
                      response["data"]["additionalInformation"]["isExpiredPass"]
                    );
                    dealLogin(response["data"], state.yjChannelId);
                  } else if (response["status"] == 1006) {
                    ElMessage({
                      message: "请修改密码,即将跳转修改密码页.......",
                      type: "warning",
                    });
                    setTimeout(
                      () =>
                        router.push({
                          path: "/modifyPassword",
                          query: {
                            userName: state.pForm.loginId.trim(),
                          },
                        }),
                      3000
                    );
                  } else if (response["status"] == 1100) {
                    state.authMobile =
                      response["data"]["additionalInformation"]["mobile"];
                    var reg = /(\d{3})\d{4}(\d{4})/;
                    state.enmobile = state.authMobile.replace(reg, "$1****$2");
                    state.againAuthModal = true;
                    refreshCode();
                  } else if (response["status"] == 1001) {
                    ElMessage({
                      message:
                        response["msg"] +
                        "<br/>*注意：请正确输入并注意字母需区分大小写，特殊字符需区分中英文。",
                      dangerouslyUseHTMLString: true,
                      type: "warning",
                      center: true,
                    });
                    refreshCode();
                  } else {
                    ElMessage({
                      message: response["msg"],
                      type: "warning",
                    });
                    refreshCode();
                  }
                  //refreshCode();
                });
              } else {
                ElMessage({
                  message: "请阅读并选中协议～",
                  type: "warning",
                });
              }
            } else {
              return;
            }
          } else {
            valided = false;
            return;
          }
        });
      }
      if (state.yjChannelId) {
        datas.channelId = state.yjChannelId;
      }
    };
    //info是用户有权限的系统的数组
    if (localStore("userAppRoles")) {
      var info = JSON.parse(localStore("userAppRoles"));
    }
    const dealLogin = (data, yjChannelId) => {
      let addData = data["additionalInformation"];
      localStore("userId", addData["userId"]);
      localStore("userName", addData["userName"]);
      localStore("userRole", addData["userRole"]);
      localStore("userOrganizes", JSON.stringify(addData["userOrganizes"]));
      localStore("fullName", addData["fullName"]);
      localStore("portrait", addData["portrait"]);
      localStore("registerType", addData["registerType"]);
      localStore("userAppRoles", JSON.stringify(addData["userAppRoles"]));
      localStore("channelId", yjChannelId);
      localStore("mobile", addData["mobile"]);
      // 带有悦测评重定向的地址
      if (router.currentRoute.value.query.redirect_url) {
        window.location.href = decodeURIComponent(
          router.currentRoute.value.query.redirect_url
        );
        return;
      }
      // 带有悦教大讲堂重定向的地址
      if (yjChannelId) {
        if (yjChannelId == "fjyd") {
          window.location.href = "yuejiao/route.html?channel=fjyd";
          return;
        }
      }
      if (addData["defaultPass"] || addData["changePass"]) {
        //需要修改密码的情况
        ElMessage({
          message: "请修改密码,即将跳转修改密码页.......",
          type: "warning",
        });
        setTimeout(
          () =>
            router.push({
              path: "/setUserInfo",
              query: {
                changePwd: true,
              },
            }),
          3000
        );
      } else {
        //不需要修改密码的情况
        var haveAuthArr = [];
        var log_id = localStore("loginId");
        info = JSON.parse(localStore("userAppRoles"));
        if (log_id && (info.length != 0 || addData["userRole"] != 4)) {
          // 有路径跳转地址时
          var redirectUrl = getRedirect();
          if (redirectUrl) {
            clearRedirect();
            window.location.href = redirectUrl;
          } else if (state.hrefCode && state.hrefUrl) {
            info.forEach(function (item) {
              haveAuthArr.push(item.appCode);
            });
            if (haveAuthArr.indexOf(state.hrefCode) !== -1) {
              window.location.href = state.hrefUrl;
            } else {
              // 无权限，跳转至新页面
              router.push({
                path: "/noAuth",
                query: {
                  appCode: state.hrefCode,
                },
              });
            }
            if (window.opener != null) {
              var isIndex = window.opener.location.pathname.substring(
                window.opener.location.pathname.lastIndexOf("/")
              );
              if (isIndex == "/login") {
                //如果父页面是login或者为空则不刷新页面
              } else {
                window.opener.location.reload(true);
              }
            }
          } else {
            router.push("/myApp");
          }
        } else {
          ElMessage({
            message: "您没有任何子系统的权限",
            type: "warning",
          });
          router.push("/myApp");
        }
      }
    };

    const againAuth = () => {
      if (!state.againAuthCode) {
        state.againWaring = true;
        state.againWaringText = "验证码不能为空！";
        return;
      }
      toAgainAuth({
        mobile: state.authMobile,
        code: state.againAuthCode,
      }).then((response) => {
        if (response["status"] == 0) {
          state.againWaring = false;
          var rolesArr =
            response["data"]["additionalInformation"]["userAppRoles"];
          for (var i = 0; i < rolesArr.length; i++) {
            var moduleInfo = moduleInfos[rolesArr[i].appCode];
            if (moduleInfo) {
              rolesArr[i].url = moduleInfo["url"];
              rolesArr[i].img = "image/" + moduleInfo["logo"];
            }
          }
          localStore("token", response["data"]["accessToken"]);
          localStore("tokenType", response["data"]["tokenType"]);
          localStore("refreshToken", response["data"]["refreshToken"]);
          dealLogin(response["data"], state.yjChannelId);
        } else {
          state.againWaring = true;
          state.againWaringText = "短信验证码错误或已失效！";
        }
      });
    };
    const pRules = {
      loginId: [
        {
          required: true,
          message: "请输入账号/手机号/邮箱",
          trigger: "blur",
        },
      ],
      password: [{ validator: validatePassNull, trigger: "blur" }],
      imageCode: [{ validator: validateNull, trigger: "blur" }],
    };
    const sRules = {
      tel: [{ validator: validateTel, trigger: "blur" }],
      smsCode: [{ validator: validateNull, trigger: "blur" }],
    };
    const setTime = () => {
      var timer;
      if (state.sendBtnShow) {
        state.sendBtnShow = false;
        timer = setInterval(() => {
          if (state.counter > 0 && state.counter <= 60) {
            state.counter--;
          } else {
            state.sendBtnShow = true;
            state.counter = 60;
            clearInterval(timer);
          }
        }, 1000);
      }
    };

    return {
      ...toRefs(state),
      refreshCode,
      login,
      sendVerify,
      verifyCode,
      pRules,
      sRules,
      setTime,
      againAuth,
    };
  },
};
</script>

<style lang="scss" scoped>
.login-Card {
  width: 430px;
  margin: 0 auto;
}
.waring {
  color: red;
  font-size: 12px;
  margin-top: 10px;
}
</style>
